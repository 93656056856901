import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import GalleryCardComponent from "../../../app/src/Components/common/GalleryCardComponent";
import {IMAGE_HEIGHT, IMAGE_WIDTH} from "../../CatalogueConstants";
import {URL_VIDEODETAIL} from "../../../app/src/Components/Routes/Location";

const ContentCardComponent = ({content, handleSelectedMedia, imageCard}) => {

    const {t} = useTranslation();

    return (
        <div className="PlaylistItemContainer VideoContainer" key={content.id}
             onClick={() => handleSelectedMedia(content.id)}>
            <GalleryCardComponent image={imageCard} width={IMAGE_WIDTH} height={IMAGE_HEIGHT}
                                  contentType={content.type?.name} tags={content.tags}/>
            <div className="contentFooter">
                <div className="PlaylistItemTitle">{content.title}</div>

                <div className="PlayFavContainer">
                    <div className="boton-play">
                        <Link className="BotonVer BotonVerV2" to={{
                            pathname: URL_VIDEODETAIL + "/" + content.id,
                            state: {
                                url: content.video?.url,
                                type: content.video?.type,
                                id: content.id
                            }
                        }}>
                            {t('ficha.seeDetail')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ContentCardComponent;
