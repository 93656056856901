import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector } from "react-redux";
import './Search.css';
import {Row, Col} from 'reactstrap';
import AlertNotification from '@components/GUI/Alert/Alert';
import {SearchContext} from '@search/Context/SearchContext';
import {URL_VIDEODETAIL} from "@components/Routes/Location";
import SearchEmpy from "@resources/Images/empty.png";
import VideoType from '@detail/Pages/DetailPage/VideoType'
import {search} from '@core'
import useOutput from "../../../../app/src/Utils/Hooks/useOutput";
import {turnOffLoadingActionCreator} from "../../../../app/src/actions/commonActions";

let updateText = null;
let text;

function Search(props) {

    const dispatch = useDispatch();
    const outputFn = useOutput();
    const {t} = useTranslation();
    const [widgets, setWidgets] = useState([]);

    const [error, setError] = useState(false);
    const [controller, setController] = useState(null);

    const searchInput = document.getElementById("suggestions-autocomplete");

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator())
    }

    useEffect(() => {
        let con = new search.controller;
        let binding = con.bind(model, o => outputFn(o, output, search))

        setController(con)
        turnOffLoading();

        return () => {
            if (updateText) {
                updateText("")
            }
            con.unbind(binding)
        }
    }, []);

    useEffect(() => {
        if (searchInput !== null) {
            searchInput.focus();
        }
    }, [searchInput])

    const model = () => {};

    const output = () => {};

    const selectedVideo = (id) => {
        controller?.dispatch(new search.events.LogSelectItem(id))
    }

    const searchText = useSelector(state => state.commonState.searchText)

    return (
        <div className="Search">
            <SearchContext.Consumer>
                {searchContext => {
                    text = searchContext.text
                    setError(searchContext.error)
                    updateText = searchContext.updateText
                }}
            </SearchContext.Consumer>

            {text &&
            <SearchContext.Consumer>
                {searchContext => {
                    setWidgets(searchContext.listWidgets)
                }}
            </SearchContext.Consumer>
            }
            {widgets &&
            <>
                {widgets.length !== 0 &&
                    <span className="Results">
                        <div className="clarifyLayer"><div className="resultsText" >{t('results', {text: searchText})}</div></div>
                    </span>
                }
                {widgets.length === 0 && !props.error &&
                <div className={"contentSearch"}>
                    <img className="NoResultsImage" src={SearchEmpy} alt="SearchEmpty"/>
                    <div className="NoResultsText1">{t('search.find')}</div>
                    <div className="NoResultsText2">{t('search.find2')}</div>
                </div>
                }
                {error &&
                <AlertNotification/>
                }
            </>
            }

            <div className="PlaylistContainerSearch">
                <Row>
                    {widgets &&
                    widgets.map((w) =>
                        <Col xs="12" sm="12" md="6" lg="" className="SearchVideo">
                            <div className="SearchImg">
                                <Link to={URL_VIDEODETAIL + "/" + w.id}
                                      className="PlaylistItemSearch" key={w.title}
                                      onClick={() => selectedVideo(w.id)}>
                                    <img className="PlaylistItemImageSearch" src={w.banner?.url} alt={w.title}/>
                                    <div className="PlaylistItemTitleSearch">{w.title}</div>
                                </Link>
                                <VideoType subtitle={w}/>
                            </div>
                        </Col>
                    )
                    }
                </Row>
            </div>
        </div>
    );
}

export default Search;
