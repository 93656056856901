import { Translate } from "@material-ui/icons";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { WIDGET_TYPE_LIVE_V2 } from "../contanst";

export function useTranslate() {
  const { t } = useTranslation();
  return t;
}

export function useIsLogin() {
  const { keycloak, initialized } = useKeycloak();
  return initialized && keycloak.authenticated;
}

export function useFormattedDateTime(startTimeMs = 0, typeBanner) {
  const startDate = new Date(startTimeMs?.toNumber()).toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
  const startTime = new Date(startTimeMs?.toNumber()).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  return typeBanner == WIDGET_TYPE_LIVE_V2 ? `${startDate} ${startTime}` : startDate;
}
