import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useSelector } from "react-redux";
import history from "./History";
import { WithMenuRoute, WithMenuRoutePrivate } from "./WithMenuRoute";
import {
  URL_LEGAL,
  URL_HOME,
  URL_REGISTER,
  URL_LOGIN,
  URL_SELECTLOGIN,
  URL_SEARCH,
  URL_CATALOGUE,
  URL_CONTENT,
  URL_USER,
  URL_RANKING,
  URL_PLAYER,
  URL_VIDEODETAIL,
  URL_ERRORPAGE,
  URL_PUBLISH,
  URL_NAME_PUBLISH,
  URL_REJECT,
  URL_NAME_REJECT,
  URL_PRODUCTS,
  URL_SUCCESS_CHECKOUT,
  URL_CANCEL_CHECKOUT,
  URL_SECTION_CLUBS,
} from "./Location";
import Login from "@user/Pages/Login/Login";
import SelectAge from "@user/Pages/SelectAge/SelectAge";
import Splash from "@app/Pages/Splash/Splash";
import Register from "@user/Pages/Register/Register";
import HomeComponent from "@home/Pages/Home/HomeComponent";
import Legal from "@user/Pages/Legal/Legal";
import Search from "@search/Pages/Buscar/Search";
import ErrorPage from "@app/Pages/Error/ErrorPage";
import Catalogo from "@catalogue/Pages/Catalogo/Catalogo";
import Content from "@catalogue/Pages/Content/Content";
import SectionComponent from "@catalogue/Pages/Section/SectionComponent";
import Player from "@player/Pages/Player/PlayerPage";
import VideoDetail from "@detail/Pages/DetailPage/VideoDetailPage";
import Ranking from "../../../../user/src/Pages/Usuario/Ranking";
import Perfil from "../../../../user/src/Pages/Usuario/PerfilUsuario";
import PublishRejectComponent from "@publish/Pages/publish/PublishRejectComponent.jsx";
import CheckoutComponent from "@checkout/Pages/Checkout/CheckoutComponent";
import { GlobalLoadingComponent } from "../Loader/GlobalLoadingComponent";
import ExpiredSessionModalComponent from "../common/ExpiredSessionModalComponent";
import ProductsComponent from "../../../../products/src/Pages/Products/ProductsComponent";

function Routes(props) {
  const [keycloak, initialized] = useKeycloak();
  const sessionExpiredModal = useSelector((state) => state.commonState.isSessionExpired);

  const loading = useSelector((state) => state.commonState.loading);

  if (!initialized || !keycloak.clientId) {
    return null;
  }

  const { changekeycloak, userAppController, menuAppController, scrollRef } = props;

  return (
    <Router history={history}>
      <Switch>
        <Route path={`*${URL_REGISTER}`} component={Register} />
        <Route
          path={`${URL_LOGIN}${URL_HOME}`}
          render={(props) => <Legal {...props} userAppController={userAppController} guest={true} />}
        />
        <Route
          path={`${URL_LEGAL}${URL_LOGIN}${URL_HOME}`}
          render={(props) => (
            <HomeComponent
              {...props}
              userAppController={userAppController}
              menuAppController={menuAppController}
              guest={true}
            />
          )}
        />
        <Route
          exact
          path={`*${URL_HOME}`}
          render={(props) => (
            <HomeComponent {...props} userAppController={userAppController} menuAppController={menuAppController} />
          )}
        />
        <Route
          exact
          path={`*${URL_HOME}/:contentId`}
          render={(props) => (
            <HomeComponent {...props} userAppController={userAppController} menuAppController={menuAppController} />
          )}
        />
        <Route path={URL_LOGIN} component={Login} />
        <Route path={URL_LEGAL} component={Legal} />
        <Route path={URL_ERRORPAGE} component={ErrorPage} />
        <Route
          path={`${URL_PUBLISH}/:contentId`}
          render={() => <PublishRejectComponent event={URL_NAME_PUBLISH} userAppController={userAppController} />}
        />
        <Route
          path={`${URL_REJECT}/:contentId`}
          render={() => <PublishRejectComponent event={URL_NAME_REJECT} userAppController={userAppController} />}
        />
        <Route path={URL_SELECTLOGIN} render={(props) => <SelectAge changekeycloak={changekeycloak} {...props} />} />
        {/*Paxinas internas*/}
        <WithMenuRoute
          exact
          path={"/section/discover"}
          component={Catalogo}
          userAppController={userAppController}
          menuAppController={menuAppController}
        />
        <WithMenuRoute
          path={"/section/:sectionName"}
          component={SectionComponent}
          userAppController={userAppController}
          menuAppController={menuAppController}
        />
        <WithMenuRoute
          path={URL_SEARCH}
          component={Search}
          userAppController={userAppController}
          menuAppController={menuAppController}
        />
        <WithMenuRoute
          path={URL_CATALOGUE}
          component={Catalogo}
          userAppController={userAppController}
          menuAppController={menuAppController}
        />
        <WithMenuRoute
          path={`${URL_CONTENT}/:type/:id`}
          component={Content}
          userAppController={userAppController}
          menuAppController={menuAppController}
          {...props}
        />
        <WithMenuRoute
          path={`${URL_PLAYER}/:id`}
          component={Player}
          menuAppController={menuAppController}
          nofooter={true}
          {...props}
        />
        <WithMenuRoute
          path={`${URL_VIDEODETAIL}/:id`}
          component={VideoDetail}
          userAppController={userAppController}
          menuAppController={menuAppController}
          scrollRef={scrollRef}
          {...props}
        />
        <WithMenuRoute
          path={`${URL_PRODUCTS}/:id`}
          component={ProductsComponent}
          menuAppController={menuAppController}
          {...props}
        />
        <WithMenuRoute
          path={`${URL_SUCCESS_CHECKOUT}/:id`}
          component={CheckoutComponent}
          menuAppController={menuAppController}
          success={true}
          {...props}
        />
        <WithMenuRoute
          path={`${URL_CANCEL_CHECKOUT}/:id`}
          component={CheckoutComponent}
          menuAppController={menuAppController}
          success={false}
          {...props}
        />
        <WithMenuRoutePrivate path={URL_RANKING} component={Ranking} userAppController={userAppController} />
        <WithMenuRoutePrivate
          path={URL_USER}
          component={Perfil}
          userAppController={userAppController}
          menuAppController={menuAppController}
        />

        {/*Paxinas por defecto*/}
        <Route component={Splash} />
      </Switch>
      {loading && <GlobalLoadingComponent />}
      {sessionExpiredModal && <ExpiredSessionModalComponent />}
    </Router>
  );
}

export default Routes;
