import React, { useState, useEffect, useRef } from "react";
import { TRANSITION_DURATION } from "../PlaylistConstants";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import {
  URL_CONTENT,
  URL_WIDGET,
} from "../../../../../app/src/Components/Routes/Location";
import defaultBanner from "@app/Resources/Images/default_banner.png";

import "react-multi-carousel/lib/styles.css";
import "@detail/Pages/DetailPage/CarrouselStyle.css";
import { evaluateItemClass, LIMIT_ITEMS } from "./utils";
import Spinner from "react-bootstrap/Spinner";
import { responsiveGroup } from "@detail/Pages/DetailPage/CarrouselStyle";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { actionTypeContainer } from "../../../../../app/src/Utils/utils";

const GroupWidgetComponent = ({
  key,
  className,
  style,
  titulo,
  hasMoreContents,
  id,
  typeBanner,
  playlist,
  playSpinner,
  access,
  titleColor,
  turnOnLoading,
  contentId = null,
}) => {
  const [overOnCard, setOverOnCard] = useState(false);
  const { t } = useTranslation();

  const minimumItems = +playlist?.length < LIMIT_ITEMS ? " BlockCarousel" : " ";

  const getPageId = (w) => {
    return w.screen ? w.screen.id : w.id;
  };

  return (
    <div
      className="PlaylistContainer CarouselContainer GroupWidget"
      key={key}
      style={{ marginBottom: "35px" }}
    >
      <div className={"Playlist " + className} style={style}>
        <div className="Titulo" style={{ display: "flex" }}>
          <p style={{ width: "auto", color: titleColor }}>{titulo}</p>
          {hasMoreContents && (
            <Link
              to={actionTypeContainer(access).ROUTE + "/" + id}
              style={{
                padding: "0px 0px 20px 0px",
                display: "flex",
                width: "120px",
              }}
              className="Titulo button-link-secondary"
            >
              <div className="ShowMoreText">{t("ficha.showmore")}</div>
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{ margin: "4px 0 0 5px" }}
              />
            </Link>
          )}
        </div>
        {playlist?.length > 0 && (
          <Carousel
            swipeable={true}
            draggable
            showDots={false}
            responsive={responsiveGroup}
            infinite={false}
            autoPlay={false}
            keyBoardControl={false}
            transitionDuration={TRANSITION_DURATION}
            customTransition="transform 1000ms ease-in-out 0s"
            containerClass={
              "carousel-container PlaylistCollection " +
              typeBanner +
              " " +
              minimumItems
            }
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px CardContainer"
            centerMode={false}
          >
            {playlist?.map((w, i) => (
              <div
                className={evaluateItemClass({
                  i,
                  overOnCard,
                  playlist,
                  itemClass: "PlaylistItemContainer",
                })}
                key={i}
                onMouseEnter={() => setOverOnCard(i)}
                onMouseLeave={() => setOverOnCard(false)}
              >
                <Link
                  to={actionTypeContainer(access).ROUTE + "/" + getPageId(w)}
                  onClick={() => contentId !== w.id && turnOnLoading()}
                  className="PlaylistItem"
                  key={w.id + "-" + w.image?.url + w.color}
                >
                  <div className="PlaylistItem">
                    <img
                      className="PlaylistItemImage"
                      alt={t('global.image') + w.title}
                      src={w.image?.url ?? defaultBanner}
                    />
                    <div className="PlaylistItemTitle">{w.title}</div>
                  </div>
                </Link>
              </div>
            ))}
          </Carousel>
        )}
      </div>
      {playSpinner && (
        <div className="SpinnerContainer">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
};

export default GroupWidgetComponent;
