import React, { useState } from "react";

import { BarraBusqueda } from "./BarraBusqueda";
import { URL_SEARCH } from "../../../../../Components/Routes/Location";
import { ESCAPE_KEY, SHOW_SEARCH } from "../../../../../Utils/Constants";

export function BarraBusquedaMobile({ buttonSearch, handelShow, show }) {
  const isSearchSection = () => {
    return window.location.href.includes(URL_SEARCH);
  };
  const [resetValue, setResetValue] = useState(false);

  const handleDisplaySearchBar = () => {
    handelShow(SHOW_SEARCH);
    setResetValue(true);
  };
  function cancel(event) {
    event.key === ESCAPE_KEY && handelShow(SHOW_SEARCH) && setResetValue(true);
  }

  return (
    <>
      <div className={`BarraBusquedaMobil ${isSearchSection() ? "active" : show && "active"}`}>
        <BarraBusqueda buttonSearch={buttonSearch} resetValue={resetValue} cancel={cancel} focus={show} />
      </div>
      {!isSearchSection() ? (
        show ? (
          <div className={"PecharBarraBusqueda"} onClick={() => handleDisplaySearchBar()}>
            <ion-icon name="close" />
          </div>
        ) : (
          <div className="IconSearhMobile" onClick={() => handleDisplaySearchBar()}>
            <ion-icon name={buttonSearch.section.iconId} />
          </div>
        )
      ) : null}
    </>
  );
}
