import createStore from "../configureStore";
import rootReducer from "../reducers";
import { interops } from "../../core-framework";
import { useLocation } from "react-router-dom";
import {
  ANIMATION_GET_GOAL_ACHIEVEMENTS,
  ANIMATION_GET_GOAL_PLAY,
  ANIMATION_GET_GOAL_REWARDS,
  ROUTES_BY_WIDGET_ACTION,
} from "./Constants";
import { CONTENT_TYPE_CONTAINER } from "../../../detail/src/Pages/DetailPage/PlaylistConstants";
import defaultBanner from "@app/Resources/Images/default_banner.png";
import { URL_HOME } from "../Components/Routes/Location";
import jwtDecode from "jwt-decode";

export const store = createStore(rootReducer);

export const arrayFormatter = (value) => {
  const array = [];
  const it = value.iterator();
  while (it.hasNext()) {
    const ach = it.next();
    array.push(ach);
  }
  return array;
};

export const compareUserProfileObjects = (model, state) => {
  return JSON.stringify(model) !== JSON.stringify(state);
};

export const setAchievementsAndRewardsInLocalStorage = (o) => {
  const achievements = interops.toJsArray(o.actionResult.achievements);
  const rewards = interops.toJsArray(o.actionResult.rewards);
  if (!o.actionResult.hasFailed) {
    if (achievements.length > 0 || rewards.length > 0) {
      localStorage.setItem(ANIMATION_GET_GOAL_PLAY, 1);
      localStorage.setItem(ANIMATION_GET_GOAL_ACHIEVEMENTS, JSON.stringify(!!achievements[0] ? achievements[0] : {}));
      localStorage.setItem(ANIMATION_GET_GOAL_REWARDS, JSON.stringify(!!rewards[0] ? rewards[0] : {}));
    }
  }
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const isContentTypeContainer = (typeName) => {
  return typeName === CONTENT_TYPE_CONTAINER;
};
export const actionTypeContainer = (widgetAction) => {
  return !!widgetAction ? ROUTES_BY_WIDGET_ACTION[widgetAction] : "/";
};

export const checkMenuActive = (match, location, a, sectionSelected, sections) => {
  if (!sectionSelected) {
    if (location.aboutProps) {
      return location.aboutProps?.dinamic === a?.section?.screenId;
    } else {
      const isFirstHomeSection = sections.indexOf(a) === 0;
      return location.pathname === URL_HOME && !!isFirstHomeSection;
    }
  } else {
    return sectionSelected === a?.section?.iconId;
  }
};

export const handleShowPassword = (numberPassword, showPassword) => {
  let arrayInputs = [];
  switch (numberPassword) {
    case 0:
      arrayInputs = [!showPassword[0], showPassword[1], showPassword[2]];
      break;
    case 1:
      arrayInputs = [showPassword[0], !showPassword[1], showPassword[2]];
      break;
    case 2:
      arrayInputs = [showPassword[0], showPassword[1], !showPassword[2]];
      break;
    default:
      return arrayInputs;
  }
  return arrayInputs;
};

export const renderImage = (content) => {
  return content.banner?.url ? content.banner.url : defaultBanner;
};

export const generateSlug = (text) => {
  const removeAccents = (str) => {
    const accents = [
      /[\300-\306]/g,
      /[\340-\346]/g, // A, a
      /[\310-\313]/g,
      /[\350-\353]/g, // E, e
      /[\314-\317]/g,
      /[\354-\357]/g, // I, i
      /[\322-\330]/g,
      /[\362-\370]/g, // O, o
      /[\331-\334]/g,
      /[\371-\374]/g, // U, u
      /[\321]/g,
      /[\361]/g, // N, n
      /[\307]/g,
      /[\347]/g, // C, c
    ];

    const nonAccents = ["A", "a", "E", "e", "I", "i", "O", "o", "U", "u", "N", "n", "C", "c"];

    for (let i = 0; i < accents.length; i++) {
      str = str.replace(accents[i], nonAccents[i]);
    }

    return str;
  };

  return removeAccents(text)
    .toLowerCase()
    .replace(/[^\w\s-]/g, "") // Remover caracteres especiales
    .replace(/\s+/g, "-") // Reemplazar espacios en blanco por guiones
    .replace(/--+/g, "-") // Reemplazar múltiples guiones por uno solo
    .trim(); // Eliminar espacios en blanco al principio y al final
};

export const decodeToken = (token) => {
  const userData = jwtDecode(token);

  return userData.locale;
};

export const setHtmlLang = (lang) => {
  document.documentElement.lang = lang;
};

export const getLang = () => {
  let language = localStorage.getItem("petisgo-locale");
  if (!language) {
    language = document.documentElement.lang;
    if (!language) {
      language = "es";
    }
  }
  return language;
};

export const getSectionName = (section) => {
  let name = section.translations.find((t) => t.language === getLang())?.name;
  if (!name) {
    name = section?.section?.name;
  }
  return name;
};
