import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { SearchProvider } from "@search/Context/SearchProvider";
import { SearchContext } from "@search/Context/SearchContext";
import { URL_SEARCH } from "@components/Routes/Location";
import { toArray } from "@components/Kotlin/KotlinUtils";
import "./BarraBusqueda.css";
import { search } from "@core";
import useOutput from "../../../../../Utils/Hooks/useOutput";
import { TEXT_SPLIT_LENGTH } from "./BarraBusquedaConstants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from "react-redux";
import { saveSearchTextActionCreator } from "../../../../../actions/commonActions";

export function BarraBusqueda(props) {
  const { resetValue, cancel, focus } = props;
  const { t } = useTranslation();
  const outputFn = useOutput();
  const { updateText } = useContext(SearchContext);
  const { updateWidgets } = useContext(SearchContext);
  const { updateError } = useContext(SearchContext);
  const dispatch = useDispatch();

  const [text, setText] = useState("");
  const [error, setError] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [textSplit, setTextSplit] = useState([]);
  const [controller, setController] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  const passwordMode = useSelector((state) => state.commonState.passwordMode);

  useEffect(() => {
    let con = new search.controller();
    let binding = con.bind(model, (o) => outputFn(o, output, search));
    con.dispatch(search.events.LogSearchView);
    setController(con);

    return () => {
      con.unbind(binding);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!resetValue) {
      handleInputChange("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValue]);

  const filter = (event) => {
    if (event.target !== undefined) {
      setText(event.target.value);
      dispatch(saveSearchTextActionCreator(event.target.value));

      if (updateText) {
        updateText(event.target.value);
      }
      const lwl = new search.events.PerformSearch(event.target.value);
      setTextSplit(event.target.value.split(""));
      controller?.dispatch(lwl);
    }
  };

  const model = (o) => {
    if (o.searchResults) {
      setWidgets(toArray(o.searchResults));
      if (updateWidgets) {
        updateWidgets(toArray(o.searchResults));
      }
    }
    if (o.suggestions) {
      setSuggestions(toArray(o.suggestions).map((sug) => sug.title));
    }
  };

  const output = (o) => {
    if (o.error) {
      setError(true);
      if (updateError) {
        updateError(true);
      }
    }
  };

  useEffect(() => {
    if (controller) {
      const event = new search.events.GetSuggestions(text);
      controller?.dispatch(event);
    }
  }, [controller, text]);

  const handleInputChange = (data) => {
    setText(data);
    if (updateText) {
      updateText(data);
    }
    const lwl = new search.events.PerformSearch(data);
    setTextSplit(data.split(""));
    controller?.dispatch(lwl);
  };


  return (
    <div className={"Barra-Busqueda"}>
      <SearchContext.Consumer>
        {(searchContext) => {
          setText(searchContext.text ? searchContext.text : "");
        }}
      </SearchContext.Consumer>

      <Autocomplete
        disablePortal
        id="suggestions-autocomplete"
        open={text.length > TEXT_SPLIT_LENGTH}
        options={suggestions}
        sx={{ width: 300 }}
        closeIcon={false}
        popupIcon={false}
        freeSolo
        inputValue={text}
        disabled={passwordMode}
        noOptionsText={t("search.results.text")}
        onInputChange={(ev, text) => handleInputChange(text)}
        renderInput={(params) => (
          <div className={"SearchContainer"}>
            <div className={"IconSearch"}>
              <ion-icon name={props.buttonSearch.section.iconId} />
            </div>
            <TextField
              id="searchBarTextField"
              className="InputNome"
              inputRef={(input) => input && (focus ? input.focus() : input.blur())}
              onKeyDown={cancel}
              {...params}
              inputProps={{
                ...params.inputProps,
                'aria-label': t("global.button.go.to.player")
              }} 
              placeholder={t("search.placeholder")}
              onChange={(event) => filter(event)}
            />
          </div>
        )}
      />

      <SearchProvider arrayResult={widgets} text={text} error={error} />
      {textSplit.length >= TEXT_SPLIT_LENGTH && <Redirect to={URL_SEARCH} />}
    </div>
  );
}
