import React, {useEffect, useState} from "react";

import useDynamicRefs from 'use-dynamic-refs';
import useWindowDimensions from '@components/DOM/Screen/ScreenDimensions';
import "react-multi-carousel/lib/styles.css";
import '@detail/Pages/DetailPage/CarrouselStyle.css';

import {
    DATA_LOADING_STATUS_LOADED,
    WIDGET_TYPE_CIRCULAR, WIDGET_TYPE_GROUP_SCREEN,
    WIDGET_TYPE_GROUP_WIDGET,
    WIDGET_TYPE_HIGHLIGHT,
    WIDGET_TYPE_LIVE, WIDGET_TYPE_LIVE_2, WIDGET_TYPE_LIVE_VERTICAL,
    WIDGET_TYPE_NEWS
} from "../../../../app/src/Utils/Constants";

import LiveWidgetComponent from "./Widgets/LiveWidgetComponent";
import HighlightWidgetComponent from "./Widgets/HighlightWidgetComponent";
import GroupWidgetComponent from "./Widgets/GroupWidgetComponent";
import CircularWidgetComponent from "./Widgets/CircularWidgetComponent";
import DefaultWidgetComponent from "./Widgets/DefaultWidgetComponent";

import "react-multi-carousel/lib/styles.css";
import '@detail/Pages/DetailPage/CarrouselStyle.css';
import TextWidgetComponent from "./Widgets/TextWidgetComponent";
import LiveVerticalWidgetComponent from "./Widgets/LiveVerticalWidgetComponent";
import Live_2_WidgetComponent from "./Widgets/Live_2_WidgetComponent";
import {
    WIDGET_TYPE_GRID,
    WIDGET_TYPE_BANNER,
    WIDGET_TYPE_POSTER,
    WIDGET_TYPE_LIVE_V2,
    WIDGET_TYPE_PROGRESS,
    WIDGET_TYPE_GRID_GROUP, ORIGIN_HOME, WIDGET_TYPE_BANNER_CLICK
} from '../../../../app/src/Components/widget_v2/contanst';
import DefaultWidgetComponent_V2 from '../../../../app/src/Components/widget_v2/DefaultWidgetComponent_V2';
import { WIDGET_TYPE_HIGHLIGHT_V2 } from '../../../../app/src/Components/widgetHightligth_v2/constants';
import WidgetHightligth_v2 from '../../../../app/src/Components/widgetHightligth_v2/WidgetHightligth_v2';
import {useSelector} from "react-redux";
import {URL_CONTENT, URL_SCREEN, URL_SECTION, URL_WIDGET} from "../../../../app/src/Components/Routes/Location";
import BannerClickComponent from "./Widgets/BannerClickComponent";
import ListScreenComponent from "./Widgets/ListScreenComponent";

const Playlist = (props) =>{
    const { isSmall } = useWindowDimensions();
    const [getRef, setRef] = useDynamicRefs();
    const { turnOffLoading, turnOnLoading, access,
        titleColor, contentId = null, pageLocation = null,
        statusLoader = DATA_LOADING_STATUS_LOADED,
        origin = ORIGIN_HOME } = props;
    const loading = useSelector(state => state.commonState.loading);
    const [playSpinner, setPlaySpinner] = useState(true);

    useEffect(()=> {
        if(props.playlist?.length > 0) {
            if(!!loading) turnOffLoading()
            if(!!playSpinner) setPlaySpinner(false);
        }
    }, [props.playlist])
    const renderWidget = () => {
        switch (props?.typeBanner?.toUpperCase()) {
            case WIDGET_TYPE_HIGHLIGHT:
                return <HighlightWidgetComponent playlist={props.playlist} rotateTime={props.rotateTime} setRef={setRef}
                                                 getRef={getRef}
                                                 access={props.access} clickCallback={props.clickCallback}
                                                 playSpinner={playSpinner}
                />;
            case WIDGET_TYPE_HIGHLIGHT_V2.toUpperCase():
                return <WidgetHightligth_v2
                    playlist={props.playlist}
                    rotateTime={props.rotateTime}
                    setRef={setRef}
                    getRef={getRef}
                    access={props.access}
                    clickCallback={props.clickCallback}
                    playSpinner={playSpinner}
                />
            case WIDGET_TYPE_LIVE_VERTICAL:
                return <LiveVerticalWidgetComponent key={props.key} className={props.className} style={props.style}
                    titulo={props.titulo}
                    isSmall={isSmall}
                    typeBanner={props.typeBanner} playlist={props.playlist}
                    access={props.access}
                    clickCallback={props.clickCallback} hideSubtitle={props.hideSubtitle}
                                                    playSpinner={playSpinner}
                    titleColor={titleColor} />;
            case WIDGET_TYPE_LIVE:
                return <LiveWidgetComponent key={props.key} className={props.className} style={props.style}
                    titulo={props.titulo}
                    isSmall={isSmall}
                    typeBanner={props.typeBanner} playlist={props.playlist}
                    access={props.access}
                    clickCallback={props.clickCallback} hideSubtitle={props.hideSubtitle}
                                            playSpinner={playSpinner}
                    titleColor={titleColor} />;
            case WIDGET_TYPE_LIVE_2:
                return <Live_2_WidgetComponent key={props.key} className={props.className} style={props.style}
                    titulo={props.titulo}
                    isSmall={isSmall}
                    typeBanner={props.typeBanner} playlist={props.playlist}
                    access={props.access}
                    clickCallback={props.clickCallback} hideSubtitle={props.hideSubtitle}
                                               playSpinner={playSpinner}
                    titleColor={titleColor} />;
            case WIDGET_TYPE_GROUP_WIDGET:
                return <GroupWidgetComponent isSmall={isSmall} key={props.key} className={props.className}
                                             style={props.style} access={access}
                                             titulo={props.titulo}
                                             turnOnLoading={turnOnLoading}
                                             hasMoreContents={props.hasMoreContents} id={props.id}
                                             typeBanner={props.typeBanner}
                                             playSpinner={playSpinner}
                                             playlist={props.playlist} titleColor={titleColor} contentId={contentId} action={URL_CONTENT + URL_WIDGET} />;
            case WIDGET_TYPE_GROUP_SCREEN:
                return <ListScreenComponent isSmall={isSmall} key={props.key} className={props.className}
                                             style={props.style} access={access}
                                             titulo={props.titulo}
                                             turnOnLoading={turnOnLoading}
                                             hasMoreContents={props.hasMoreContents} id={props.id}
                                             typeBanner={props.typeBanner}
                                             playSpinner={playSpinner}
                                             playlist={props.playlist} titleColor={titleColor} contentId={contentId}
                                             action={URL_SCREEN}/>;

            case WIDGET_TYPE_CIRCULAR:
                return <CircularWidgetComponent isSmall={isSmall} key={props.key} className={props.className}
                    style={props.style} access={access}
                    titulo={props.titulo}
                    turnOnLoading={turnOnLoading}
                    hasMoreContents={props.hasMoreContents} id={props.id}
                    typeBanner={props.typeBanner}
                    playlist={props.playlist} clickCallback={props.clickCallback}
                                                playSpinner={playSpinner}
                    controller={props.controller} event={props.event} titleColor={titleColor} />;
            case WIDGET_TYPE_NEWS:
                return <TextWidgetComponent isSmall={isSmall} key={props.key} className={props.className}
                    style={props.style}
                    titulo={props.titulo}
                    hasMoreContents={props.hasMoreContents} id={props.id}
                    typeBanner={props.typeBanner}
                    playlist={props.playlist} clickCallback={props.clickCallback}
                                            playSpinner={playSpinner}
                    controller={props.controller} event={props.event} titleColor={titleColor} />;
            case WIDGET_TYPE_BANNER_CLICK:
                return <BannerClickComponent playlist={props.playlist} title={props.titulo}/>;
            case WIDGET_TYPE_GRID:
            case WIDGET_TYPE_BANNER:
            case WIDGET_TYPE_LIVE_V2:
            case WIDGET_TYPE_POSTER:
            case WIDGET_TYPE_PROGRESS:
            case WIDGET_TYPE_GRID_GROUP:
                return <DefaultWidgetComponent_V2
                    access={access}
                    backgroundColor={props.backgroundColor}
                    backgroundImage={props.backgroundImage}
                    event={props.event}
                    hasMoreContents={props.hasMoreContents}
                    id={props.id}
                    playlist={props.playlist}
                    playSpinner={playSpinner}
                    titleColor={titleColor}
                    titulo={props.titulo}
                    typeBanner={props.typeBanner}
                />;

            default:
                return <DefaultWidgetComponent isSmall={isSmall} key={props.key} className={props.className}
                    style={props.style} access={access}
                    titulo={props.titulo} turnOffLoading={turnOffLoading}
                    hasMoreContents={props.hasMoreContents} id={props.id}
                    typeBanner={props.typeBanner} turnOnLoading={turnOnLoading}
                    playlist={props.playlist} clickCallback={props.clickCallback}
                    controller={props.controller} event={props.event} detail={props.detail}
                                               playSpinner={playSpinner}
                                               titleColor={titleColor} contentId={contentId} pageLocation={pageLocation}
                    statusLoader={statusLoader} origin={origin}
                />;
        }
    }
    const widget = props?.typeBanner && renderWidget();
    return (
        <>
            {widget}
        </>
    );
}

export default Playlist;
