import React from "react";

import {Link} from "react-router-dom";

import {MULTITENANT_CONSTANTS, TENANT} from "../../../../../app/src/Resources/Multitenant/tenantConstants";


const BannerClickComponent = ({playlist, title}) => {

    const bannerClickContent = playlist[0];

    return (<div>
        <div className={MULTITENANT_CONSTANTS[TENANT].classPlaylistHighlightItem} key={bannerClickContent.id + "-bannerclick"}>
                <div className={MULTITENANT_CONSTANTS[TENANT].bannerHighlightedHeightClassName + " playlistHighlightHover"} onClick={() => (bannerClickContent.apirequest !== null ? window.open(bannerClickContent.apirequest, "_blank") : undefined)}>
                        <img src={bannerClickContent.image.url}
                             alt={title} className={MULTITENANT_CONSTANTS[TENANT].classHeight}/>
                </div>
        </div>
    </div>)
};

export default BannerClickComponent;
