import React, {useState, useEffect} from "react";
import {toArray} from '@components/Kotlin/KotlinUtils';
import '../Catalogo.css';
import {Col} from "reactstrap";

const SectionsMenuComponent = ({sections, defaultSections, selectNodeParent}) => {

    const [section, setSection] = useState(defaultSections[0]);
    const [displayMenu, setDisplayMenu] = useState(false);

    const handleChangeSection = value => {
        setSection(value)
        selectNodeParent(value);
    }

    const handleDisplayMenu = () => {
        setDisplayMenu(displayMenu => !displayMenu)
    }

    useEffect(() => {
        if(defaultSections[0] !== section){
            setSection(defaultSections[0])
        }
    }, [defaultSections])

    return (
        <Col xs={6} sm={3} lg={2}  className="OrderMenuContainer">
            <div className="OrderMenu">
                <div className="MenuCatalogueContainer" onClick={handleDisplayMenu}>
                                    <span className="MenuCatalogue sectionsMenuTitle">
                                        {section}
                                    </span>
                    <span className={"IconMenuCatalogue"}>
                                        <ion-icon name="chevron-down-outline"/>
                                    </span>
                </div>
                {displayMenu && <div className="MenuDesplegableOrder">
                    {toArray(sections.rootNodes).map(n =>
                        <div type="button" className="MenuSubsectionOrder"
                             key={n.nodeName ? n.nodeName : n.value?.nodeName}
                             onClick={() => handleChangeSection(n.nodeName ? n.nodeName : n.value?.nodeName)}>
                            <span className="button-text">{n.nodeName ? n.nodeName : n.value?.nodeName}</span>
                        </div>)}
                </div>}
            </div>
        </Col>
    )
};

export default SectionsMenuComponent;
