import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useParams} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import {Col, Row} from "reactstrap";


import AlertNotification from '@components/GUI/Alert/Alert';
import {toArray} from '@components/Kotlin/KotlinUtils';
import {URL_VIDEODETAIL} from '@components/Routes/Location';
import VideoType from '@detail/Pages/DetailPage/VideoType';
import {content, user} from '@core'
import {useDispatch, useSelector} from "react-redux";
import NoResult from "@resources/Images/empty.png";
import './Content.css';
import "react-multi-carousel/lib/styles.css";
import PetisgoLoader from "../../../../app/src/Resources/Multitenant/petisgo/PetisgoLoader";
import {EVENT_TYPE_CATEGORY, EVENT_TYPE_PARTICIPANT, EVENT_TYPE_WIDGET} from "./ContentConstants";
import {turnOffLoadingActionCreator, turnOnLoadingActionCreator} from "../../../../app/src/actions/commonActions";
import GalleryCardComponent from "../../../../app/src/Components/common/GalleryCardComponent";
import {IMAGE_HEIGHT, IMAGE_WIDTH, TEXT_NAME_BEGINNING} from "../../../CatalogueConstants";
import {compareUserProfileObjects, renderImage} from "../../../../app/src/Utils/utils";
import {DATA_LOADING_STATUS_LOADED} from "../../../../app/src/Utils/Constants";
import useOutput from "../../../../app/src/Utils/Hooks/useOutput";


function Content(props) {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    let {type} = useParams();
    let {id} = useParams();
    const {userAppController} = props;


    const outputFn = useOutput();

    const [contentData, setContentData] = useState(null);
    const [controller, setController] = useState(null);
    const [error, setError] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [nameOrder, setNameOrder] = useState(null);

    const turnOnLoading = () => {
        dispatch(turnOnLoadingActionCreator())
    }

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator())
    }
    const languageSelected = useSelector(state => state.commonState.languageSelected)

    useEffect(() => {
        turnOnLoading()
        setNameOrder("catalogue.most_recent_first")

        const con = new content.controller
        let binding = con.bind(model, o => outputFn(o, output, content));
        const event = getEvent();
        con.dispatch(event);
        setController(con);

        return () => {
            con.unbind(binding);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (controller) {
            const userCon = userAppController;
            let userBinding = userCon.bind(userModel, o => outputFn(o, userOutput, user));
            userCon.dispatch(user.events.LogUserProfileView)

            return () => {
                userCon.unbind(userBinding);
            }
        }
    }, [controller])

    const model = o => {
        const formattedModel = {};

        formattedModel.loading = o.areContentsLoading.name$;

        if (o.contents !== null) {
            formattedModel.contents = toArray(o.contents.asList());
        }

        formattedModel.title = o.title;
        formattedModel.hasMoreContents = o.hasMoreContents;
        formattedModel.availableSorts = o.availableSorts;
        formattedModel.isSearchAvailable = o.isSearchAvailable;

        if (formattedModel.loading === DATA_LOADING_STATUS_LOADED && compareUserProfileObjects(contentData, formattedModel)) {
            setContentData(formattedModel)
        }
    };

    const output = (o) => {
        if (o.error) {
            setError(true);
        }
    };

    const userModel = o => {
        if (o.language && controller) {
            const event = getEvent();
            controller.dispatch(event);
        }
    };

    const userOutput = o => {

    };

    const getEvent = () => {
        switch (type) {
            case EVENT_TYPE_WIDGET:
                return new content.events.LoadContentByWidget(id, languageSelected);
            case EVENT_TYPE_PARTICIPANT:
                return new content.events.LoadContentByParticipant(id, languageSelected);
            case EVENT_TYPE_CATEGORY:
                return new content.events.LoadContentByCategory(id, languageSelected);
            default:
                return new content.events.LoadContentById(id, languageSelected);
        }
    };

    const selectedVideo = () => {
    };

    const fetchMoreData = () => {
        const lwl = content.events.LoadMoreContent;
        controller.dispatch(lwl)
    };

    const displayMenu = () => {
        if (showMenu) {
            setShowMenu(false)
        } else {
            setShowMenu(true)
        }
    };

    const handleFoundData = (event) => {
        if (event.target !== undefined) {
            const lwl = new content.events.Search(event.target.value);
            controller.dispatch(lwl)
        }
    };

    const handleClickOrder = (name) => {
        setNameOrder(TEXT_NAME_BEGINNING + name)
        const lwl = new content.events.Sort(name);
        controller.dispatch(lwl);
        setShowMenu(false);
    };

    const getTextSort = (id) => {
        return t(TEXT_NAME_BEGINNING + id)
    };


    useEffect(() => {
        if (contentData !== null && contentData.contents) {
            turnOffLoading()
        }
    }, [contentData])



    return (
        <div>
            {contentData !== null && <div className="Catalogo Content" id="catalogo">
                <div className="Header">
                    <div className="Discover">
                        {contentData.title}
                    </div>
                </div>
                <div className="Categories">
                    <Row className="InputCatalogue">
                        {contentData.isSearchAvailable &&
                        <Col xs={12} md={6} className="InputSearchCatalogue">
                                <div className={"IconSearchCatalogue"}>
                                    <ion-icon name={"search"} style={{color: "#dfdfdf"}}/>
                                </div>
                                <input className="InputCatalogue2" type="text" placeholder={t('catalogue.find')}
                                       onChange={(text) => handleFoundData(text)}/>
                        </Col>
                        }
                        {contentData.contents && contentData.contents?.length !== 0 &&
                        <Col xs={12} md={6} className="OrderMenuContainer">
                            <div className="OrderMenu">
                                <div className="MenuCatalogueContainer" onClick={displayMenu}>
                                        <span className="MenuCatalogue">
                                            {t(nameOrder)}
                                        </span>
                                    <span className={"IconMenuCatalogue"}>
                                            <ion-icon name={"caret-down-outline"}/>
                                        </span>
                                </div>
                                {showMenu &&
                                <div className="MenuDesplegableOrder">
                                    {contentData.availableSorts && toArray(contentData.availableSorts).map((o) =>
                                        <div type="button" className="MenuSubsectionOrder" key={o}
                                             onClick={() => handleClickOrder(o)}>
                                            <span className="button-text">{getTextSort(o)}</span>
                                        </div>)}
                                </div>}
                            </div>
                        </Col>}
                    </Row>
                </div>
                {contentData.contents &&
                <div className="CatalogueVideo" id="catalogueVideo">
                    <InfiniteScroll
                        dataLength={contentData.contents?.length}
                        next={fetchMoreData}
                        hasMore={contentData.hasMoreContents}
                        loader={
                            <div className="SpinnerCatalogue">
                                <PetisgoLoader/>
                            </div>}
                        scrollableTarget="root">
                        <div className="galleryGrid">
                            {contentData.contents?.length > 0 && contentData.contents?.map(o => {
                                    return <div className="PlaylistItemContainer" key={o.id}>
                                        <Link to={URL_VIDEODETAIL + "/" + o.id} onClick={() => selectedVideo(o.id)}
                                              key={o.title}>
                                            <GalleryCardComponent image={renderImage(o)} width={IMAGE_WIDTH}
                                                                  height={IMAGE_HEIGHT}/>
                                            <div className="contentFooter">
                                                <div className="PlaylistItemTitle">{o.title}</div>
                                                <VideoType subtitle={o}/>
                                            </div>
                                        </Link>
                                    </div>
                                }
                            )}
                        </div>
                        {contentData.contents?.length === 0 &&
                        <div className="NoResultsSearch">
                            <img className="ImageNoResult" src={NoResult} alt={"Nofind"}/>
                            <div className="NoResults">{t('catalogue.noResults')}</div>
                        </div>}
                    </InfiniteScroll>
                </div>}
            </div>}
            {error && <AlertNotification/>}
        </div>);
};

export default Content;
