import React, { useEffect, useState } from "react";
import { Redirect, withRouter, useHistory } from "react-router-dom";
import { getNextScreen } from "@components/Routes/Location";
import "./Splash.css";
import { splash } from "@core";
import { useKeycloak } from "@react-keycloak/web";
import { USER_DEFAULT } from "../../Utils/Constants";
import { URL_HOME, URL_LEGAL } from "../../Components/Routes/Location";

const Splash = withRouter(() => {
  const [nextScreen, setNextScreen] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { keycloak } = useKeycloak();
  let history = useHistory();
  const userId = localStorage.getItem("clientId");
  const localUserGuest = localStorage.getItem("OTT-GUEST");

  useEffect(() => {
    getNextScreen(splash.controller).then((n) => setNextScreen(n));
    if (!keycloak.authenticated) {
      if (userId === USER_DEFAULT || localUserGuest === "true") {
        setNextScreen(URL_HOME);
      } else {
        setTimeout(() => setLoaded(true), 1500);
      }
    }
  }, [keycloak.authenticated]);

  useEffect(() => {
    if (!!nextScreen) {
      localStorage.setItem("OTT-URLSection", nextScreen);
      history.push(nextScreen);
    }
  }, [nextScreen]);

  if (!keycloak.authenticated && (!nextScreen || !loaded) && userId !== USER_DEFAULT) {
    return <></>;
  }
  return <Redirect to={nextScreen} />;
});

export default Splash;
