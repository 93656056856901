import React, { useEffect, useRef, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import $ from "jquery";
import Routes from "@components/Routes/Routes";
import { UserProvider } from "@user/Context/UserProvider";
import { SearchProvider } from "@search/Context/SearchProvider";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { splash, user, menu, interops } from "@core";
import i18n from "./i18n";
import { compareUserProfileObjects, setAchievementsAndRewardsInLocalStorage } from "./Utils/utils";
import { MULTITENANT_CONSTANTS, TENANT } from "./Resources/Multitenant/tenantConstants";
import Favicon from "react-favicon";
import { useDispatch, useSelector } from "react-redux";
import {
  displayOnBoardingActionCreator,
  loadTenantStylesActionCreator,
  saveTenantLanguageActionCreator,
  saveUserDataActionCreator,
} from "./actions/commonActions";
import AnimationGetGoalComponent from "./Components/common/AnimationGetGoalComponent";
import {
  ANIMATION_GET_GOAL_ACHIEVEMENTS,
  ANIMATION_GET_GOAL_PLAY,
  ANIMATION_GET_GOAL_REWARDS,
  DEVICES,
} from "./Utils/Constants";
import OnBoardingComponent from "./Components/common/OnBoarding/OnBoardingComponent";
import ModalAppsComponent from "./Components/common/ModalAppsComponent";
import { ARRAY_URLS_OPEN_PAGES, URL_LEGAL, URL_SECTION } from "./Components/Routes/Location";
import { useHistory } from "react-router-dom";
import { getTenantRules } from "./services/services";
import { getUserDataService, getMenu } from "./services/services";
import { setHtmlLang } from "./Utils/utils";

function App() {
  const dispatch = useDispatch();
  let history = useHistory();
  const scrollRef = useRef();
  const { keycloak, initialized } = useKeycloak();
  const accessToken = keycloak?.token;

  const openPage = () => {
    const pathname = document.location.pathname;
    let findOpenPage = false;
    if (pathname.includes(URL_SECTION)) {
      findOpenPage = true;
    } else
      ARRAY_URLS_OPEN_PAGES.map((page) => {
        if (pathname.indexOf(page) !== -1) {
          findOpenPage = true;
        }
      });
    return findOpenPage;
  };

  useEffect(() => {
    /*    const localUserGuest = localStorage.getItem("OTT-GUEST");*/

    if (
      /*localUserGuest !== 'true' &&*/
      !!keycloak &&
      initialized &&
      !keycloak.authenticated &&
      !openPage()
    ) {
      keycloak.login();
    } else if (/*localUserGuest !== 'true' &&*/ !!keycloak && initialized && keycloak.authenticated) {
      onKeycloakTokens(keycloak.token, keycloak.refreshToken);
      handleSetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, keycloak]);

  const renderApp =
    useSelector((state) => state.commonState.onBoarding) || !MULTITENANT_CONSTANTS[TENANT]?.showOnBoarding;

  const handleDisplayOnBoarding = (value) => {
    dispatch(displayOnBoardingActionCreator(value));
  };

  const handleSkipOnBoarding = (value) => {
    localStorage.setItem("petisgo-skip-onboarding", JSON.stringify(value));
    handleDisplayOnBoarding(value);
  };

  const tenantStyles = useSelector((state) => state.commonState.tenantStyles);
  const storageLocale = localStorage.getItem("petisgo-locale") || 'en';

  const tenantValues = useSelector((state) => state.commonState);

  const [controller, setController] = useState(null);
  const [userAppController, setUserAppController] = useState(null);
  const [menuAppController, setMenuAppController] = useState(null);
  const [appData, setAppData] = useState({});
  const [userData, setUserData] = useState({});

  const loadTenantStyles = (data) => {
    dispatch(loadTenantStylesActionCreator(data));
  };

  const [playingAnimationGetGoal, setPlayingAnimationGetGoal] = useState(false);
  const [achievementsState, setAchievementsState] = useState(null);
  const [rewardsState, setRewardsState] = useState(null);
  const [modalAppsMobile, setModalAppsMobile] = useState(false);
  const [appUrlData, setAppUrlData] = useState({});
  const [device, setDevice] = useState(null);
  const [tenantLanguages, setTenantLanguages] = useState([]);


  const openAnimationGetGoal = () => {
    setTimeout(() => {
      setPlayingAnimationGetGoal(true);
    }, 4000);
  };

  const closeAnimationGetGoal = () => {
    setPlayingAnimationGetGoal(false);
    localStorage.setItem(ANIMATION_GET_GOAL_PLAY, 0);
    localStorage.setItem(ANIMATION_GET_GOAL_ACHIEVEMENTS, JSON.stringify({}));
    localStorage.setItem(ANIMATION_GET_GOAL_REWARDS, JSON.stringify({}));
    setAchievementsState("");
    setRewardsState("");
  };

  const getUserDataCallback = (response) => {
    setUserData(response);
    dispatch(saveUserDataActionCreator(response));
  };

  const getMenuCallback = (response) => {
    const mainInterface = response?.data.find((item) => item.title === "MAIN" || item.title === "LOGADO");
    setTenantLanguages(mainInterface.languages);
    dispatch(saveTenantLanguageActionCreator(mainInterface.languages));
  };

  useEffect(() => {
    if (!!keycloak && initialized) {
      // Petición que trae los datos de usuario
      const userToken = keycloak?.token;
      const username = keycloak?.tokenParsed?.preferred_username;
      if (!!username) {
        getUserDataService(MULTITENANT_CONSTANTS[TENANT].tenantServer, username, userToken, getUserDataCallback);
      }
      // Petición que nos trae las interfaces
      getMenu(getMenuCallback);
    }
  }, [keycloak, initialized]);

  const getFromLocalStorage = (field) => {
    return localStorage.getItem(field);
  };

  useEffect(() => {
    if (userAppController) {
      userAppController.unbind();
    }
    const userController = new user.controller();
    if (menuAppController) {
      menuAppController.unbind();
    }
    setUserAppController(userController);
    const menuController = new menu.controller();
    setMenuAppController(menuController);

    const con = new splash.controller();
    // splash-controller-changes: bind ahora recibe una funcion para actualizaciones del modelo
    let binding = con.bind(handleSplashModel, handleSplashOutput);
    // splash-controller-changes: hay que enviar el evento GetFirstScreen para empezar la carga del nextScreen
    con.dispatch(splash.events.GetFirstScreen);
    setController(con);
    const activeAnimation = getFromLocalStorage(ANIMATION_GET_GOAL_PLAY);
    if (activeAnimation === 1) {
      const rewardsLocal = getFromLocalStorage(ANIMATION_GET_GOAL_REWARDS);
      !!rewardsLocal && setRewardsState(JSON.parse(rewardsLocal));
      const achievementLocal = getFromLocalStorage(ANIMATION_GET_GOAL_ACHIEVEMENTS);
      !!achievementLocal && setAchievementsState(JSON.parse(achievementLocal));
      openAnimationGetGoal();
    }

    return () => {
      con.unbind(binding);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addCSSVariables(conf) {
    if (conf) {
      $.each(conf, function (i, val) {
        if (val && typeof val != "function") {
          if (val.url) {
            $("#root").css("--" + i.replace(".", ""), "url(" + val.url + ")");
          } else if ((val + "").toLowerCase().startsWith("http")) {
            $("#root").css("--" + i.replace(".", ""), "url(" + val + ")");
          } else {
            $("#root").css("--" + i.replace(".", ""), val);
          }
        }
      });
    }
  }

  const changeKeycloak = () => {
    //   setKeycloak(k);
  };

  const handleSplashModel = (model) => {
    if (model.loadState.name === "RESET") {
      // El core no esta haciendo nada, la carga de la siguiente pantalla no ha empezado o ha terminado en error
      // model.nextScreen es null
    }
    if (model.loadState.name === "LOADING") {
      // El core esta cargando cual es la siguiente pantalla
      // model.nextScreen es null
    }
    if (model.loadState.name === "LOADED") {
      // El core ha indicado cual es la siguiente pantalla
      // model.nextScreen NO es null
      if (model.nextScreen) {
        if (model.nextScreen.name === "LEGAL") {
          history.push(URL_LEGAL);
          //el core indica que el usuario/invitado tiene nuevas condiciones legales para aceptar
        }
        // splash-controller-changes: actualizamos los estilos con los datos de backend
        const styles = model.styles;
        addCSSVariables(styles);

        // splash-controller-changes: ahora las urls de las apps de ios y android vienen por aqui
        const androidAppUrl = model.androidAppUrl;
        const iosAppUrl = model.iosAppUrl;
        const appUrlDataModel = {};
        if (androidAppUrl) {
          appUrlDataModel.androidAppUrl = androidAppUrl;
          setModalAppsMobile(true);
        }
        if (iosAppUrl) {
          appUrlDataModel.iosAppUrl = iosAppUrl;
          setModalAppsMobile(true);
        }
        setAppUrlData(appUrlDataModel);
        // splash-controller-changes: y el token de acceso se coge desde aqui
      }
    }
  };

  useEffect(() => {
    // Seleccionamos el idioma disponible:
    // 1- El del usuario si tenemos datos
    // 2- El idioma por defecto que nos viene en la petición de la interfaz general "MAIN" si el usuario no tiene datos
    // 3- El que almacenamos localmente por defecto en caso contrario a las condiciones anteriores
    if ((!!keycloak && initialized) || i18n.language) {
      if (!!userData && !!userData.language) {
        i18n.changeLanguage(userData.language);
        localStorage.setItem("petisgo-locale", userData.language);
      } else if (!userData && !!tenantLanguages) {
        i18n.changeLanguage(tenantLanguages[0]);
        localStorage.setItem("petisgo-locale", tenantLanguages[0]);
      } else {
        i18n.changeLanguage(MULTITENANT_CONSTANTS[TENANT]?.defaultLocale);
        localStorage.setItem("petisgo-locale", MULTITENANT_CONSTANTS[TENANT]?.defaultLocale);
      }
    }
  }, [accessToken, userData]);

  const handleSetData = () => {
    const appDataModel = { ...appData };

    if (accessToken) {
      appDataModel.token = accessToken;
      appDataModel.isLoaded = true;
    }
    if (compareUserProfileObjects(appData, appDataModel)) {
      setAppData(appDataModel);
    }
  };

  const handleSplashOutput = (o) => {
    // splash-controller-changes: ahora llegan estas notificaciones
    // splash-controller-changes: UnexpectedError indica un error inesperado en la carga inicial
    // splash-controller-changes: se puede utilizar para registrar el error o mostrar un error al usuario
    if (interops.getKotlinName(o) === "UnexpectedError") {
    }
    // splash-controller-changes: una petición a dado timeout, puede indicar problemas de conexion a internet o con el backend
    if (interops.getKotlinName(o) === "RequestTimedOut") {
    }
    // splash-controller-changes: porque aqui llega la confirmación de la accion LogLoginView
    if (interops.getKotlinName(o) === "GameActionSent") {
      setAchievementsAndRewardsInLocalStorage(o);
    }
  };

  const onKeycloakTokens = (token, refreshToken) => {
    localStorage.setItem("clientId", keycloak.clientId);
    const store = new splash.events.StoreAuth(token, refreshToken);
    controller.dispatch(store);
  };

  const isTenantFreeToPlay = (response) => {
    return !(!response.areAnonAllowed && response.hasMonetization);
  };
  const callbackTenant = (response) => {
    const isFreeToPlay = isTenantFreeToPlay(response);
    loadTenantStyles({ ...MULTITENANT_CONSTANTS[TENANT], isFreeToPlay: isFreeToPlay });
  };

  useEffect(() => {
    const tenants = Object.keys(MULTITENANT_CONSTANTS);

    tenants.find((item) => item === TENANT)
      ? getTenantRules(callbackTenant)
      : loadTenantStyles(MULTITENANT_CONSTANTS.default);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tenantStyles?.pageTitle) {
      document.title = tenantStyles.pageTitle;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantStyles]);

  useEffect(() => {
    setHtmlLang(storageLocale);
  }, [storageLocale]);

  const toggleOpenModal = () => {
    setModalAppsMobile(!modalAppsMobile);
  };

  const detectDevice = () => {
    const platform = window.navigator.platform;

    for (const device of Object.keys(DEVICES)) {
      if (platform.includes(device)) {
        setDevice(DEVICES[device]);
      }
    }
  };

  useEffect(() => {
    detectDevice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {" "}
      {!!tenantStyles?.favicon && <Favicon url={tenantStyles?.favicon} />}
      <>
        {renderApp !== false ? (
          <UserProvider>
            <SearchProvider>
              <div ref={scrollRef} />
              <Routes
                changekeycloak={(k) => changeKeycloak(k)}
                scrollRef={scrollRef}
                userAppController={userAppController}
                menuAppController={menuAppController}
              />
            </SearchProvider>
          </UserProvider>
        ) : (
          <OnBoardingComponent handleSkipOnBoarding={handleSkipOnBoarding} />
        )}
      </>
      {/*{appData?.error && !keycloak && <RouteError/>}*/}
      {!!playingAnimationGetGoal && (
        <AnimationGetGoalComponent
          playingAnimationGetGoal={playingAnimationGetGoal}
          closeAnimationGetGoal={closeAnimationGetGoal}
          achievementsState={achievementsState}
          rewardsState={rewardsState}
        />
      )}
      {!!modalAppsMobile && !!device && (
        <ModalAppsComponent
          className={"classModalApps"}
          isOpen={true}
          toggleOpenModal={toggleOpenModal}
          content={"modal_apps_content"}
          title={"modal_apps_title"}
          subContent={"modal_apps_sub_content"}
          appUrlData={appUrlData}
          device={device}
        />
      )}
    </>
  );
}

export default App;
