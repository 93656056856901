import { TENANT, MULTITENANT_CONSTANTS } from "../Resources/Multitenant/tenantConstants";
import { URL_BASE, URL_CAS, URL_SERGIO, URL_SGA, URL_IDEN, URL_ANALYTICS, URL_REM } from "./servicesConstants";

export const getWidgetsServices = (sectionSelected, callback) => {
  fetch(`${URL_BASE}${URL_SERGIO}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/screens/${sectionSelected}?fields=*.*`)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }
      return response.json();
    })
    .then((data) => {
      callback(data);
      /*setResponseSectionData(data);
            getBannersWidget(data.widgets[0]?.widgets_id?.id);*/
      /*creatObjectSectionToPrint(data)*/
      // Hacer algo con los datos recibidos
    })
    .catch((error) => {
      console.error("Error:", error);
      // Manejar el error de la solicitud
    });
};

export const getBannersWidgetServices = (id, callback) => {
  fetch(
    `${URL_BASE}${URL_SGA}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/widgets/${id}/content?filter[status]=published`,
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }
      return response.json();
    })
    .then((data) => {
      callback(data.data);
      // Hacer algo con los datos recibidos
    })
    .catch((error) => {
      console.error("Error:", error);
      // Manejar el error de la solicitud
    });
};

export const getMenu = (callback) => {
  fetch(`${URL_BASE}${URL_SERGIO}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/interfaces`)
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getTenantRules = (callback) => {
  fetch(`${URL_BASE}${URL_CAS}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/rules`)
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

// ------------ USER ---------------
export const getUserDataService = (appname, username, token, callback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/user/${username}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getUserInterestsService = (appname, username, token, callback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/user/${username}/interests`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
export const updateUserInterestsService = (appname, username, body, token, callback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/user/${username}/interests/list`, {
    method: "POST",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data.data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const deleteUserInterestsService = (appname, username, interests, token, callback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/user/${username}/interests/list?interests=${interests}`, {
    method: "DELETE",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getFileService = (appname, fileId, token, callback) => {
  fetch(`${URL_BASE}${URL_REM}${appname}/files/${fileId}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const updateUserDataService = (appname, username, token, callback, body) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/user/${username}`, {
    method: "PATCH",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    });
};

// ------------ ANALYTICS ---------------
export const logUserAnalyticService = (appname, username, action, key, value) => {
  fetch(`${URL_BASE}${URL_ANALYTICS}${appname}/user/${username}?action=${action}?key=${key}?value=${value}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).catch((error) => {
    console.error("Error", error);
  });
};
