import React from "react";
import ActionButtons from "../ActionButtons/ActionButtons";
import PropTypes from "prop-types";
import style from "./style.module.css";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../../../app/src/Resources/Multitenant/tenantConstants";
import MetaData from "../MetaData/MetaData";
import MediaCover from "../MediaCover/MediaCover";
import Rating from "../Rating/Rating";

function PlaylistItem({ item, access, event, typeBanner }) {
  return (
    <section key={item.id} className={style.playlistItemContainer}>
      <MediaCover data={item} typeBanner={typeBanner} event={event} access={access} />
      <section
        className={`${style.playlistItemFooter} ${
          MULTITENANT_CONSTANTS[TENANT].ImageWithText
            ? style.playlistItemFooter__topText
            : style.playlistItemFooter__bottomText
        }`}
      >
        <MetaData
          category={item.category}
          startTimeMs={item.startTimeMs}
          seasons={item.seasons}
          typeBanner={typeBanner}
        />
        {MULTITENANT_CONSTANTS[TENANT].showRating && <Rating data={item} subtitle={item.subtitle} />}
        <div className={style.playlistItemTitle}>{item.title}</div>
        <div className={style.playlistItemSubtitle}>{item.subtitle}</div>
        {MULTITENANT_CONSTANTS[TENANT].ImageWithText && <ActionButtons data={item} access={access} event={event} />}
      </section>
    </section>
  );
}

export default PlaylistItem;

PlaylistItem.propTypes = {
  playlist: PropTypes.array,
};
