export const WIDGET_TYPE_GRID = "GRID_V2";

export const WIDGET_TYPE_GRID_GROUP = "GRID_GROUP_V2";

export const WIDGET_TYPE_LIVE_V2 = "DIRECTO_V2";
export const WIDGET_TYPE_POSTER = "POSTER_V2";
export const WIDGET_TYPE_PROGRESS = "WIDGET_PROGRESS_V2";
export const WIDGET_TYPE_BANNER = "BANNER_V2";
export const WIDGET_TYPE_BANNER_CLICK = "BANNER_CLICK";

export const WIDGET_TYPE_FEATURED_V2 = "FEATURED_V2";

export const WIDGET_ACTION_PLAY = "PLAY";
export const TYPE_BANNER_BANNER_LARGE = "BANNER_LARGE";

export const TRANSITION_DURATION = "250";
export const LIMIT_ITEMS = "5";

export const DATA_LOADING_STATUS_LOADED = "LOADED";

export const ORIGIN_SECTIONS = "ORIGIN_SECTIONS";
export const ORIGIN_HOME = "ORIGIN_HOME";
