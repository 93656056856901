import React from 'react';
import {useTranslation} from 'react-i18next';
import icon360 from '@app/Resources/Images/360.svg'
import iconLive from '@app/Resources/Images/live.svg'
import iconVR from '@app/Resources/Images/VR.svg'
import iconAR from '@app/Resources/Images/AR.svg'
import iconFORKED from '@app/Resources/Images/bifurcado.png'
import iconTiivii from '@app/Resources/Images/tiivii.svg'
import iconPdf from '@app/Resources/Images/pdf.svg'
import {
    MULTITENANT_CONSTANTS,
    TENANT,
} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {
    VIDEO_TYPE_LIVE,
    VIDEO_TYPE_LIVE_TIIVII,
    VIDEO_TYPE_VOD_360, VIDEO_TYPE_VOD_AR, VIDEO_TYPE_VOD_CONTAINER,
    VIDEO_TYPE_VOD_FORKED, VIDEO_TYPE_VOD_VR, VIDEO_TYPE_PDF
} from "../../../../app/src/Utils/Constants";

function VideoType({subtitle, isLiveWidget=false, onAir=false  }) {
    const {t} = useTranslation();

    const VIDEO_TYPES = {
        [VIDEO_TYPE_VOD_360] : {
            ICON : icon360
        },
        [VIDEO_TYPE_LIVE] : {
            ICON : iconLive
        },
        [VIDEO_TYPE_LIVE_TIIVII] : {
            ICON : iconTiivii
        },
        [VIDEO_TYPE_VOD_FORKED] : {
            ICON : iconFORKED
        },
        [VIDEO_TYPE_VOD_AR] : {
            ICON : iconAR
        },
        [VIDEO_TYPE_VOD_VR] : {
            ICON : iconVR
        },
        [VIDEO_TYPE_PDF] : {
            ICON : iconPdf
        },
    }

    const getSubtitles = (id) => {
        return onAir ? t('subtitle.' + id) : t('subtitle.SCHEDULED');
    }

    const getClass = () =>  {
        return onAir ? " " : " VideoScheduledTag";
    }


    return (<>
            {subtitle?.type?.name === VIDEO_TYPE_VOD_CONTAINER ? MULTITENANT_CONSTANTS[TENANT].showVideoTags &&
                <div className="VideoCategory">
                      <div className="Subtag PlaylistItemSubLabel PlaylistItemSubLabelSerial">
                         {t(getSubtitles(subtitle.type.name))}
                      </div>
                </div>
               : MULTITENANT_CONSTANTS[TENANT].showAllIcons ?
                <div className="VideoType">
                      <div className="typeIcon">
                         {VIDEO_TYPES[subtitle?.type?.name]?.ICON &&
                          <img src={VIDEO_TYPES[subtitle?.type?.name]?.ICON}
                               alt={t(getSubtitles(subtitle?.type?.name))}
                              />
                            }
                      </div>
                </div> : !!isLiveWidget && <div className={"VideoLiveTag" + getClass()}>{t(getSubtitles(subtitle?.type?.name)).toUpperCase()}</div>
            }
            </>
    );
}

export default VideoType;