const server = process.env.REACT_APP_CORE_ENV;
export const URL_BASE =
  server === "dev" || server === "pre"
    ? "https://streamer-cdn-pre.ott.tiivii.com/v1/"
    : "https://streamer-cdn.ott.tiivii.com/v1/";
export const URL_SGA = "sgca/";
export const URL_SERGIO = "sergio/";
export const URL_IDEN = "iden/";
export const URL_ANALYTICS = "analytics/";
export const URL_CAS = "cas/";
export const URL_REM = "reme/";
