import React, { useEffect } from "react";
import "../BarraSuperior.css";
import { home } from "@core";
import iconLive from "@app/Resources/Images/Icon_live.svg";
import useOutput from "../../../../../Utils/Hooks/useOutput";
import { NavLink } from "react-router-dom";
import { MENU_ID_LIVE } from "../../../MenuConstants";
import { ACTION_GAME } from "../../../../../../../home/src/Pages/Home/HomeComponentConstants";
import { setAchievementsAndRewardsInLocalStorage } from "../../../../../Utils/utils";
import { turnOnLoadingActionCreator } from "../../../../../actions/commonActions";
import { useDispatch } from "react-redux";

const MenuLiveComponent = ({ sections, homeController, userAge, rol, handleChangeSection }) => {
  const dispatch = useDispatch();
  const outputFn = useOutput();

  const turnOnLoading = () => {
    dispatch(turnOnLoadingActionCreator());
  };

  useEffect(() => {
    let con;
    let binding;
    if (homeController) {
      con = homeController;
      binding = con.bind(model, (o) => outputFn(o, output, home));
    }

    return () => {
      if (homeController) {
        con.unbind(binding);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeController]);

  const model = () => {};

  const output = (o) => {
    if (o.constructor.$metadata$.simpleName === ACTION_GAME) {
      setAchievementsAndRewardsInLocalStorage(o);
    }
  };

  const changeDynamicSection = (section) => {
    turnOnLoading();
    const ev = new home.events.LoadScreen(section.screenId);
    homeController.dispatch(ev);
    handleChangeSection(section.iconId, section.screenId);
  };

  const liveSection = sections.filter((item) => item.section.id === MENU_ID_LIVE)[0];
  const controlClassByTheme = () => {
    return !rol ? "iconLiveChild" : userAge < 14 ? "iconLiveChild" : "iconLive";
  };

  return (
    !!liveSection && (
      <div className={"Button-Live"}>
        <NavLink
          to={{
            pathname: liveSection?.url,
            aboutProps: {
              dinamic: liveSection?.section.screenId,
            },
          }}
          onClick={() => changeDynamicSection(liveSection?.section)}
          key={liveSection?.section.screenId}
        >
          <img className={controlClassByTheme()} src={iconLive} alt="Live" />
        </NavLink>
      </div>
    )
  );
};

export default MenuLiveComponent;
